<template>
  <div class="app-layout is-404">
    <app-header />
    <div class="app-layout__main">
      <div class="app-layout__error">
        <p class="app-layout__error-title is-title is-size-1">404</p>
        <p class="app-layout__error-message is-text is-size-2">Page not found</p>
        <div class="app-layout__error-cta">
          <ui-button tag="app-link" to="/">Back to homepage</ui-button>
        </div>
      </div>
    </div>
    <app-footer />
    <site-icons />
  </div>
</template>

<script lang="ts" setup>
import { computed } from '#imports';
import AppHeader from '~/components/global/layout/AppHeader.vue';
import AppFooter from '~/components/global/layout/AppFooter.vue';
import SiteIcons from '~/components/global/elements/SiteIcons.vue';
const props = defineProps({
  error: {
    type: Object,
    default: null,
  },
});
const message = computed(() => (props.error && props.error.message) || '<%= messages.client_error %>');
</script>

<style lang="scss" scoped>
.app-layout {
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  min-height: 100vh;

  &__main {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: rem(60);
    padding-bottom: rem(60);
  }

  &__error {
    text-align: center;

    &-message {
      margin-top: 0.5em;
      margin-bottom: 2em;
    }
  }
}
</style>
